import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "base" */ '@/layout/Base.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/public/Home.vue')
      },
      {
        path: '/empresa',
        name: 'Empresa',
        component: () => import(/* webpackChunkName: "empresa" */ '../views/public/Empresa.vue')
      },
      {
        path: '/certificados',
        name: 'Certificados',
        component: () => import(/* webpackChunkName: "certificados" */ '../views/public/Certificados.vue')
      },
      {
        path: '/portafolio',
        name: 'Portafolio',
        component: () => import(/* webpackChunkName: "portafolio" */ '../views/public/Portafolio.vue')
      },
      {
        path: '/documentos',
        name: 'Documentos',
        component: () => import(/* webpackChunkName: "documentos" */ '../views/public/Documentos.vue')
      },
      {
        path: '/quejas',
        name: 'Quejas',
        component: () => import(/* webpackChunkName: "quejas" */ '../views/public/Quejas.vue')
      },
      {
        path: '/contactenos',
        name: 'Contactenos',
        component: () => import(/* webpackChunkName: "contactenos" */ '../views/public/Contactenos.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isLoggedIn () {
  return localStorage.getItem('auth')
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (isLoggedIn()) {
      next({
        path: '/dashboard',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
