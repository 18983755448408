import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faBars,
  faPhone,
  faClock,
  faHeart,
  faCopyright,
  faFilePdf,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons'

import {
  faTwitter,
  faFacebook,
  faGoogle,
  faPinterest
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faTwitter,
  faFacebook,
  faGoogle,
  faPinterest,
  faBars,
  faPhone,
  faClock,
  faHeart,
  faCopyright,
  faFilePdf,
  faArrowRight
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
